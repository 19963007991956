<template>
<div id="usuario-read">
    <v-dialog scrollable v-model="dialog" persistent max-width="600" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Editar usuario <span v-if="dirty">*</span></span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="mb-2">
                        <v-text-field @input="$v.usuario.name.$touch()" @blur="$v.usuario.name.$touch()" :error-messages="nameErrors" v-model="usuario.name" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mb-2">
                        <v-text-field @input="$v.usuario.email.$touch()" @blur="$v.usuario.email.$touch()" :error-messages="emailErrors" v-model="usuario.email" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Correo electrónico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mb-2">
                        <v-select @input="$v.usuario.role.$touch()" @blur="$v.usuario.role.$touch()" :error-messages="roleErrors" v-model="usuario.role" :items="roles" item-text="text" item-value="value" label="Rol" persistent-placeholder hide-details="auto">
                            <template #label>
                                Rol<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field @input="$v.usuario.password.$touch()" @blur="$v.usuario.password.$touch()" :error-messages="passwordErrors" type="password" v-model="usuario.password" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Contraseña<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field @input="$v.usuario.passwordConfirm.$touch()" @blur="$v.usuario.passwordConfirm.$touch()" :error-messages="passwordConfirmErrors" type="password" v-model="usuario.passwordConfirm" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Confirmar contraseña<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-alert dense class="mt-2" color="grey lighten-4">
                    <b>Nota:</b> Dejar en blanco los campos <i>Contraseña</i> y <i>Confirmar contraseña</i> si no se desea cambiar la contraseña
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-btn class="text-none" color="red" text @click="confirm = true">
                    Eliminar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-2" text @click="dirty == false ? $router.push('/usuarios') : unsaved = true">
                    Cerrar
                </v-btn>
                <v-btn class="text-none" color="green" text @click="update" :disabled="$v.usuario.$invalid">
                    Actualizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="unsaved" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Alerta</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Tus cambios no se guardaran, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="unsaved = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="$router.push('/usuarios')">Salir sin guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="confirm" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold red--text">
                    Advertencia
                </span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Se eliminara este elemento y no podra recuperarse, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="confirm = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="destroy()">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
export default {
    name: 'UsuarioEdit',
    data: () => ({
        loading: false,
        confirm: false,
        unsaved: false,
        dialog: true,
        usuarioUntouched: null,
        usuario: {
            name: null,
            email: null,
            role: null,
            password: null,
            passwordConfirm: null
        },
        roles: [
            { text: 'Colaborador', value: 'USER' },
            { text: 'Administrador', value: 'ADMIN' },
            { text: 'Super administrador', value: 'SUPERADMIN' }
        ],
    }),
    methods: {
        async update() {
            this.loading = true;
            await this.$axios.put('/api/user/'+this.usuario._id, this.usuario).then(response => {
                if(response.status == 200){
                    this.$store.commit('usuario/UPDATE_USUARIO', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { icon: 'mdi-check', message: 'Usuario actualizado' });
                    this.usuarioUntouched = JSON.stringify(response.data);
                    this.usuario = response.data;
                    if(response.data._id == this.session.user._id){
                        this.$store.commit('UPDATE_PROFILE', response.data);
                    }
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        },
        async destroy() {
            this.loading = true;
            await this.$axios.delete('/api/user/'+this.usuario._id).then(response => {
                if(response.status == 204){
                    this.$store.commit('usuario/DELETE_USUARIO', this.usuario._id);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { icon: 'mdi-check', message: 'Usuario eliminado' });
                    this.$router.push('/usuarios');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        },
        async read() {
            this.loading = true;
            await this.$axios.get('/api/user/' + this.$route.params.id).then(response => {
                if (response.status == 200) {
                    this.usuario = response.data;
                    this.usuarioUntouched = JSON.stringify(response.data);
                }
            }).then(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.read();
    },
    computed: {
        session() {
            return this.$store.getters.GET_SESSION;
        },
        dirty() {
            return this.usuarioUntouched != JSON.stringify(this.usuario);
        },
        nameErrors() {
            const errors = [];
            if (this.$v.usuario.name.$dirty){
                if(!this.$v.usuario.name.required){
                    errors.push('Este campo es obligatorio.')
                }
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (this.$v.usuario.email.$dirty){
                if (!this.$v.usuario.email.required) {
                    errors.push('Este campo es obligatorio.')
                }
                if (!this.$v.usuario.email.email) {
                    errors.push('Este campo debe ser una direccción de correo electrónico valida.')
                }
            }
            return errors;
        },
        roleErrors() {
            const errors = [];
            if(this.$v.usuario.role.$dirty){
                if (!this.$v.usuario.role.required) {
                    errors.push('Este campo es obligatorio.')
                }
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if(this.$v.usuario.password.$dirty){
                if (!this.$v.usuario.password.required) {
                    errors.push('Este campo es obligatorio.')
                }
                if (!this.$v.usuario.password.minLength) {
                    errors.push('La contraseña debe tener al menos 8 caracteres.')
                }
            }
            return errors;
        },
        passwordConfirmErrors() {
            const errors = [];
            if(this.$v.usuario.passwordConfirm.$dirty){
                if(!this.$v.usuario.passwordConfirm.sameAsPassword){
                    errors.push('Los valores no coinciden.')
                }
            }
            return errors;
        }
    },
    validations: {
        usuario: {
            name: {
                required
            },
            role: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                minLength: minLength(8)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        }
    }
}
</script>
